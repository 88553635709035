import React, { useState, useEffect } from 'react'
import PermissionDetailsModal from './PermissionDetailsModal'
import EditTrainerModal from './EditTrainerModal'
import LoadingComponent from './LoadingComponent'

const AdminDashboard = () => {
  const [trainersData, setTrainersData] = useState([])
  const [permissionsData, setPermissionsData] = useState([])
  const [sitesData, setSitesData] = useState([])
  const [showSiteSelection, setShowSiteSelection] = useState(false)
  const [editingTrainer, setEditingTrainer] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  // State for new trainer form
  const [newTrainerID, setNewTrainerID] = useState('')
  const [newTrainerName, setNewTrainerName] = useState('')
  const [selectedSites, setSelectedSites] = useState([])
  const [newPermissionLevel, setNewPermissionLevel] = useState('')
  const [selectedTrainerPermissions, setSelectedTrainerPermissions] = useState(
    [],
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        await Promise.all([fetchTrainers(), fetchSites()])
        setIsLoading(false) // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching data:', error)
        setIsLoading(false) // Ensure loading is set to false even if there's an error
      }
    }

    fetchData()
  }, [])

  const handleDeleteTrainer = async (trainerID) => {
    if (
      window.confirm(`Are you sure you want to delete TrainerID: ${trainerID}?`)
    ) {
      try {
        // Delete permissions associated with the trainer
        await fetch(
          `${process.env.REACT_APP_API_URL}/permissions/${trainerID}`,
          { method: 'DELETE' },
        )

        // Delete the trainer
        await fetch(
          `${process.env.REACT_APP_API_URL}/trainers/${trainerID}`,
          { method: 'DELETE' },
        )

        // Refresh the data
        await fetchTrainers()
        await fetchPermissions()
      } catch (error) {
        console.error('Error deleting trainer:', error)
      }
    }
  }

  const handleEditClick = (trainerID) => {
    const trainer = trainersData.find((t) => t.TrainerID === trainerID)
    if (trainer) {
      const trainerPermissions = permissionsData.filter(
        (p) => p.TrainerID === trainerID,
      )
      setEditingTrainer({ ...trainer, permissions: trainerPermissions })
    }
  }

  const handleSaveChanges = async (updatedTrainer) => {
    try {
      // Extract the necessary data
      const { TrainerID, selectedSiteIDs, PermissionLevel } = updatedTrainer

      // Fetch current permissions to find which ones to delete
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/permissions/${TrainerID}`,
      )
      const currentPermissions = await response.json()

      const currentSiteIDs = currentPermissions.map((perm) => perm.SiteID)
      const sitesToAdd = selectedSiteIDs.filter(
        (id) => !currentSiteIDs.includes(id),
      )
      const sitesToRemove = currentSiteIDs.filter(
        (id) => !selectedSiteIDs.includes(id),
      )

      // Add new permissions
      for (const siteID of sitesToAdd) {
        await fetch(`${process.env.REACT_APP_API_URL}/permissions`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ TrainerID, SiteID: siteID, PermissionLevel }),
        })
      }

      // Remove existing permissions
      for (const siteID of sitesToRemove) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/permissions/${TrainerID}/${siteID}`,
          {
            method: 'DELETE',
          },
        )
      }

      // Refresh data
      await fetchTrainers()
      await fetchPermissions()
    } catch (error) {
      console.error('Error saving trainer changes:', error)
    }
  }

  // useEffect for fetching permissions after trainers data is loaded
  useEffect(() => {
    if (trainersData.length > 0) {
      fetchPermissions()
    }
  }, [trainersData])

  const fetchTrainers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/trainers`,
      )
      const data = await response.json()
      setTrainersData(data)
    } catch (error) {
      console.error('Error fetching trainers:', error)
    }
  }

  const fetchPermissions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/permissions`,
      )
      const permissionsData = await response.json()
      // Merge permissions data with trainer names
      const mergedData = permissionsData.map((permission) => {
        const trainer = trainersData.find(
          (trainer) => trainer.TrainerID === permission.TrainerID,
        )
        return { ...permission, Name: trainer ? trainer.Name : 'Unknown' }
      })
      setPermissionsData(mergedData)
    } catch (error) {
      console.error('Error fetching permissions:', error)
    }
  }

  const fetchSites = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sites`)
      const data = await response.json()
      setSitesData(data)
    } catch (error) {
      console.error('Error fetching sites:', error)
    }
  }

  const handlePermissionLevelChange = (e) => {
    const selectedLevel = e.target.value
    setNewPermissionLevel(selectedLevel)

    // If 'Admin' is selected, automatically select all sites
    if (selectedLevel === 'Admin') {
      const allSiteIDs = sitesData.map((site) => site.SiteID)
      setSelectedSites(allSiteIDs)
    } else {
      setSelectedSites([]) // Clear the selection for other roles
    }
  }

  const handleSiteSelectionChange = (siteID, isEditing = false) => {
    // If editing an existing trainer
    if (isEditing) {
      setSelectedSites((prevSelectedSites) => {
        const updatedSites = { ...prevSelectedSites }
        if (updatedSites[editingTrainer.TrainerID]?.includes(siteID)) {
          updatedSites[editingTrainer.TrainerID] = updatedSites[
            editingTrainer.TrainerID
          ].filter((id) => id !== siteID)
        } else {
          updatedSites[editingTrainer.TrainerID] = [
            ...(updatedSites[editingTrainer.TrainerID] || []),
            siteID,
          ]
        }
        return updatedSites
      })
    }
    // If adding a new trainer
    else {
      setSelectedSites((prevSelectedSites) => {
        if (prevSelectedSites.includes(siteID)) {
          return prevSelectedSites.filter((id) => id !== siteID)
        } else {
          return [...prevSelectedSites, siteID]
        }
      })
    }
  }

  const handleAddTrainer = async (e) => {
    e.preventDefault()
    try {
      // Add trainer to trainers table
      await fetch(`${process.env.REACT_APP_API_URL}/trainers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ TrainerID: newTrainerID, Name: newTrainerName }),
      })

      // Add permission for each selected site
      for (const siteID of selectedSites) {
        await fetch(`${process.env.REACT_APP_API_URL}/permissions`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            TrainerID: newTrainerID,
            SiteID: siteID,
            PermissionLevel: newPermissionLevel,
          }),
        })
      }

      // Refresh data
      fetchTrainers()
      fetchPermissions()
      fetchSites()

      // Reset form fields
      setNewTrainerID('')
      setNewTrainerName('')
      setSelectedSites([])
      setNewPermissionLevel('')

      // Optionally, hide the site selection after adding
      setShowSiteSelection(false)
    } catch (error) {
      console.error('Error adding trainer and permissions:', error)
    }
  }

  const handleDetailsClick = (trainerID) => {
    const trainerPermissions = permissionsData.filter(
      (permission) => permission.TrainerID === trainerID,
    )
    setSelectedTrainerPermissions(trainerPermissions)
    setIsModalOpen(true)
  }

  const groupPermissionsByTrainer = (permissions) => {
    return permissions.reduce((acc, curr) => {
      if (acc[curr.TrainerID]) {
        acc[curr.TrainerID].permissions.add(curr.PermissionLevel)
      } else {
        acc[curr.TrainerID] = {
          Name: curr.Name,
          TrainerID: curr.TrainerID,
          permissions: new Set([curr.PermissionLevel]),
        }
      }
      return acc
    }, {})
  }

  if (isLoading) {
    return <LoadingComponent />
  }

  const groupedPermissions = groupPermissionsByTrainer(permissionsData)

  return (
    <div className="container">
      <h1 className="my-4 text-center text-danger">Admin Dashboard</h1>

      {/* Form to add new trainer */}
      <form onSubmit={handleAddTrainer} className="mb-4">
        <input
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
          className="mx-2"
          type="text"
          placeholder="Trainer ID"
          value={newTrainerID}
          onChange={(e) => setNewTrainerID(e.target.value)}
        />
        <input
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
          className="mx-2"
          type="text"
          placeholder="Name"
          value={newTrainerName}
          onChange={(e) => setNewTrainerName(e.target.value)}
        />
        <select
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
          className="mx-2"
          value={newPermissionLevel}
          onChange={handlePermissionLevelChange} // Updated handler
        >
          <option value="">Select Permission Level</option>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
          <option value="Grader">Grader</option> {/* Added option for Grader */}
        </select>
        <button
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
          className="btn btn-primary mx-2"
          type="button"
          onClick={() => setShowSiteSelection(!showSiteSelection)}
        >
          {showSiteSelection ? 'Hide Sites' : 'Select Sites'}
        </button>

        {showSiteSelection && (
          <div className="mb-3 mt-2">
            {sitesData.map((site) => {
              const isChecked = selectedSites.includes(site.SiteID)

              return (
                <div key={site.SiteID} className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`site-${site.SiteID}`}
                    value={site.SiteID}
                    checked={isChecked}
                    onChange={() => handleSiteSelectionChange(site.SiteID)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`site-${site.SiteID}`}
                  >
                    {site.SiteName} ({site.SiteID})
                  </label>
                </div>
              )
            })}
          </div>
        )}
        <button
          className="btn btn-primary mx-2"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
          type="submit"
        >
          Add Trainer
        </button>
      </form>
      <table className="table table-bordered admin-table">
        <thead>
          <tr>
            <th>Trainer ID</th>
            <th>Name</th>
            <th>Permission Level</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(groupPermissionsByTrainer(permissionsData)).map(
            (trainer, index) => (
              <tr key={index}>
                <td>{trainer.TrainerID}</td>
                <td>{trainer.Name}</td>
                <td>{Array.from(trainer.permissions).join(', ')}</td>
                <td>
                  <button
                    style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
                    className="btn btn-primary mx-2"
                    onClick={() => handleDetailsClick(trainer.TrainerID)}
                  >
                    Details
                  </button>

                  <button
                    style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
                    className="btn btn-secondary mx-2"
                    onClick={() => handleEditClick(trainer.TrainerID)}
                  >
                    Edit
                  </button>

                  <button
                    style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)' }}
                    className="btn btn-danger mx-2"
                    onClick={() => handleDeleteTrainer(trainer.TrainerID)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      <PermissionDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        trainerPermissions={selectedTrainerPermissions}
      />
      {editingTrainer && (
        <EditTrainerModal
          isOpen={!!editingTrainer}
          onClose={() => setEditingTrainer(null)}
          trainerData={editingTrainer}
          onSave={handleSaveChanges}
          sitesData={sitesData}
        />
      )}
    </div>
  )
}
export default AdminDashboard

