import React from 'react'

const formatDate = (dateString) => {
  return dateString ? dateString.split('T')[0] : ''
}

const CreditHoursModal = ({ isOpen, onClose, details, creditType }) => {
  const filteredDetails = details.filter(
    (detail) => detail.criticality === creditType,
  )

  return (
    <div className={`modal-background1 ${isOpen ? '' : 'hidden'}`}>
      <div className="modal1">
        <h3>{creditType} Credit Hours Acquired Details</h3>
        <table className="tabled">
          <thead>
            <tr>
              <th>Core Competency Code</th>
              <th>Actual Credit Hours</th> {/* Updated header */}
              <th>Completion Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredDetails.length > 0 ? (
              filteredDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.etp_mstr_core_comp_code}</td>
                  <td>{detail.etp_mstr_actl_credit_hours}</td>{' '}
                  {/* Updated field */}
                  <td>{formatDate(detail.etp_mstr_completion_date)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No details available</td>
              </tr>
            )}
          </tbody>
        </table>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  )
}

export default CreditHoursModal
