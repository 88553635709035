// LoadingComponent.js
import React from 'react'
import LoadingImage from '../tecdev.png' // Path to your loading image
import '../App.css' // Import the CSS file

const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <img
        src={LoadingImage}
        alt="Loading"
        style={{ width: '800px', height: '800px' }}
      />
    </div>
  )
}

export default LoadingComponent
