import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Navbar = ({ trainerID, onSignOut, isAdmin, isGrader }) => {
  const navigate = useNavigate()

  const handleSignOut = () => {
    if (onSignOut) {
      onSignOut()
      navigate('/')
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <NavLink className="navbar-brand mx-3" to="/">
        <img
          src="/images/cw-services.png" // Updated image path
          alt="Your Alt Text"
          style={{
            width: '135px', // Set the width as needed
            height: '80px', // Maintain aspect ratio
            // Add any other CSS properties you want
          }}
        />
      </NavLink>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink className="nav-link" to="/">
            Home
          </NavLink>
        </li>
        {isAdmin && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/admin-dashboard">
              Admin Dashboard
            </NavLink>
          </li>
        )}
        {(isAdmin || isGrader) && (
          <li className="nav-item">
            <NavLink className="nav-link" to="/grader-admin-dashboard">
              Grader Dashboard
            </NavLink>
          </li>
        )}
        {/* ... Other nav items ... */}
      </ul>
      <div className="ml-auto d-flex align-items-center">
        {trainerID ? (
          <>
            <span className="navbar-text text-info mr-4">
              Welcome Back, {trainerID}
            </span>
            <button
              className="btn btn-outline-primary mr-3"
              onClick={handleSignOut}
            >
              Sign Out
            </button>
          </>
        ) : (
          <NavLink className="btn btn-outline-primary mr-3" to="/login">
            Sign In
          </NavLink>
        )}
      </div>
    </nav>
  )
}

export default Navbar


