import React from 'react'

const CoreCompetencyModal = ({ details, onClose }) => {
  return (
    <div className="core-competency-modal">
      <div className="modal-content">
        <span className="close mb-4" onClick={onClose}>
          &times;
        </span>
        <h5>Core Competency Requirements:</h5>
        <p className="mx-3 mb-4 text-dark">{details}</p>
      </div>
    </div>
  )
}

export default CoreCompetencyModal