import React, { useState, useEffect } from 'react'

const EditTrainerModal = ({
  isOpen,
  onClose,
  trainerData,
  onSave,
  sitesData,
}) => {
  const [selectedSiteIDs, setSelectedSiteIDs] = useState([])
  const [showSites, setShowSites] = useState(false) // State to toggle site visibility
  const [searchTerm, setSearchTerm] = useState('') // State for search term

  useEffect(() => {
    if (trainerData && sitesData.length > 0) {
      const assignedSiteIDs = trainerData.permissions.map((perm) => perm.SiteID)
      setSelectedSiteIDs(assignedSiteIDs)
    }
  }, [trainerData, sitesData])

  const handleCheckboxChange = (siteID) => {
    setSelectedSiteIDs((prev) => {
      if (prev.includes(siteID)) {
        return prev.filter((id) => id !== siteID)
      } else {
        return [...prev, siteID]
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await onSave({
      TrainerID: trainerData.TrainerID,
      selectedSiteIDs,
      PermissionLevel: trainerData.permissions[0].PermissionLevel,
    })
    onClose()
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredSites = sitesData.filter((site) =>
    site.SiteID.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  if (!isOpen) return null

  return (
    <div className="my-custom-modal">
      <div className="my-custom-modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <form onSubmit={handleSubmit}>
          <h4>
            Editing Trainer:{' '}
            <span className="ml-3 text-danger">{trainerData.Name}</span>{' '}
          </h4>
          <button
            type="button"
            onClick={() => setShowSites(!showSites)}
            className="btn btn-secondary mt-2 mr-2"
          >
            {showSites ? 'Hide Sites' : 'Show Sites'}
          </button>
          {showSites && (
            <>
              <input
                type="text"
                placeholder="Search SiteID"
                value={searchTerm}
                onChange={handleSearchChange}
                className="form-control my-2"
              />
              <div>
                {filteredSites.map((site) => {
                  const isChecked = selectedSiteIDs.includes(site.SiteID)
                  return (
                    <div key={site.SiteID} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`site-${site.SiteID}`}
                        value={site.SiteID}
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(site.SiteID)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`site-${site.SiteID}`}
                      >
                        {site.SiteName} ({site.SiteID})
                      </label>
                    </div>
                  )
                })}
              </div>
            </>
          )}
          <button type="submit" className="btn btn-primary mt-2">
            Save Changes
          </button>
        </form>
      </div>
    </div>
  )
}

export default EditTrainerModal
