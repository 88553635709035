import React, { useState, useEffect, useContext } from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import HomePage from './components/HomePage'
import AdminDashboard from './components/AdminDashboard'
import GraderAdminDashboard from './components/GraderAdminDashboard'
import Navbar from './components/Navbar'
import DetailsPage from './components/DetailsPage'
import useInactivityLogout from './useInactivityLogout'
import { MSALContext } from './MSALContext'
import './App.css'
import loginImage from './tecdev.png'

const App = () => {
  const { msalInstance } = useContext(MSALContext)
  const [account, setAccount] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [isMSALReady, setIsMSALReady] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isGrader, setIsGrader] = useState(false)
  const navigate = useNavigate()
  const [permissionsFetched, setPermissionsFetched] = useState(false)

  useEffect(() => {
    if (msalInstance) {
      msalInstance
        .handleRedirectPromise()
        .then((response) => {
          if (response) {
            const accounts = msalInstance.getAllAccounts()
            if (accounts.length > 0) {
              setAccount(accounts[0])
            }
          }
        })
        .catch((error) => console.error('Error handling redirect:', error))
    }
  }, [msalInstance])

  useEffect(() => {
    if (msalInstance && account && !permissionsFetched) {
      const username = account.username
      fetch(`${process.env.REACT_APP_API_URL}/permissions/${username}`)
        .then((response) => response.json())
        .then((data) => {
          setPermissions(data)
          setIsAdmin(data.some((perm) => perm.PermissionLevel === 'Admin'))
          setIsGrader(data.some((perm) => perm.PermissionLevel === 'Grader'))
          setPermissionsFetched(true)
        })
        .catch((error) => console.error('Error fetching permissions:', error))
    }
  }, [msalInstance, account, permissionsFetched])

  useEffect(() => {
    if (msalInstance) {
      const accounts = msalInstance.getAllAccounts()
      if (accounts.length > 0) {
        setAccount(accounts[0])
      }
      setIsMSALReady(true)
    }
  }, [msalInstance])

  const handleSignOut = () => {
    msalInstance.logoutRedirect()
    navigate('/')
  }
  
  // Use the custom hook
  useInactivityLogout(msalInstance, handleSignOut)

  const handleSignIn = async () => {
    if (msalInstance) {
      try {
        await msalInstance.loginRedirect()
      } catch (error) {
        console.error('Error during login:', error)
      }
    } else {
      console.error('MSAL instance is not initialized')
    }
  }

  return (
    <div className="app">
      {!account && isMSALReady ? (
        <div className="login-container">
          <img src={loginImage} alt="Login" />
          <h3>Electronic Training Plan</h3>
          <button onClick={handleSignIn}>Sign In</button>
        </div>
      ) : (
        <>
          <div className="background-image"></div>
          <Navbar
            trainerID={account ? account.username : ''}
            onSignOut={handleSignOut}
            isAdmin={isAdmin}
            isGrader={isGrader} // Pass isGrader prop to Navbar
          />
          <div className="container mt-3">
            <Routes>
              <Route
                path="/"
                element={
                  <HomePage trainerID={account ? account.username : ''} />
                }
              />
              {isAdmin && (
                <Route
                  path="/admin-dashboard"
                  element={
                    <AdminDashboard
                      trainerID={account ? account.username : ''}
                    />
                  }
                />
              )}
              {(isAdmin || isGrader) && (
                <Route
                  path="/grader-admin-dashboard"
                  element={
                    <GraderAdminDashboard
                      trainerID={account ? account.username : ''}
                    />
                  }
                />
              )}
              <Route
                path="/details/:alias"
                element={
                  <DetailsPage trainerID={account ? account.username : ''} />
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </>
      )}
    </div>
  )
}

export default App;
