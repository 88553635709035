import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import EditDatesModal from './EditDatesModal'
import './DetailsPage.css'
import LoadingComponent from './LoadingComponent'
import CoreCompetencyModal from './CoreCompetencyModal'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './FailedWoDetailsModal.css'
import './CoreCompetencyModal.css'
import FailedWoDetailsModal from './FailedWoDetailsModal'
import CreditHoursModal from './CreditHoursModal'
import './CreditHoursModal.css'

const DetailsPage = ({ trainerID }) => {
  const [traineeData, setTraineeData] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [filteredData, setFilteredData] = useState(null)
  const [searchCompCode, setSearchCompCode] = useState('')
  const [coreCompDetails, setCoreCompDetails] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [failedWoDetails, setFailedWoDetails] = useState(null)
  const [showFailedWoModal, setShowFailedWoModal] = useState(false)
  const [woPassedCount, setWoPassedCount] = useState({})
  const [woFailedCount, setWoFailedCount] = useState({})
  const [totalCreditHours, setTotalCreditHours] = useState(0)
  const [showCreditHoursModal, setShowCreditHoursModal] = useState(false)
  const [creditHoursDetails, setCreditHoursDetails] = useState([])

  const { alias } = useParams()

  const formatDate = (dateString) => {
    return dateString ? dateString.split('T')[0] : ''
  }

  const fetchWoCounts = async (coreCompCode) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/etp-mstr/wo-counts/${alias}/${coreCompCode}`,
      )

      if (!response.ok) {
        console.error('Failed to fetch WO counts for', coreCompCode)
        return
      }

      const { passedCount, failedCount } = await response.json()

      console.log(
        `Counts for ${coreCompCode}: Passed - ${passedCount}, Failed - ${failedCount}`,
      )

      setWoPassedCount((prev) => ({ ...prev, [coreCompCode]: passedCount }))
      setWoFailedCount((prev) => ({ ...prev, [coreCompCode]: failedCount }))
    } catch (error) {
      console.error('Error fetching WO counts:', error)
    }
  }

  // Call fetchWoCounts for each core competency code
  useEffect(() => {
    if (traineeData) {
      traineeData.forEach((record) => {
        fetchWoCounts(record.etp_mstr_core_comp_code)
      })
    }
  }, [traineeData])

  useEffect(() => {
    console.log('Alias:', alias)
    fetchTraineeData()
  }, [alias])

  useEffect(() => {
    if (traineeData) {
      const filtered = traineeData.filter((record) =>
        record.etp_mstr_core_comp_code.includes(searchCompCode),
      )
      setFilteredData(filtered)
    }
  }, [searchCompCode, traineeData])

  const handleInfoIconClick = async (coreCompCode) => {
    await fetchCoreCompDetails(coreCompCode)
    setShowModal(true)
  }

  const getCourseStatusClass = (courseStatus) => {
    switch (courseStatus) {
      case 'Completed':
        return 'course-status-completed'
      case 'Started':
        return 'course-status-started'
      case '':
      case null:
        return 'course-status-empty' // Handle empty status
      default:
        return '' // Default case
    }
  }

  useEffect(() => {
    // Function to fetch total credit hours
    const fetchTotalCreditHours = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/total-credit-hours/${alias}`,
        )
        if (!response.ok) throw new Error('Failed to fetch total credit hours')
        const data = await response.json()
        setTotalCreditHours(data.totalCreditHours || 0)
      } catch (error) {
        console.error('Error fetching total credit hours:', error)
      }
    }

    if (alias) {
      fetchTotalCreditHours()
    }
  }, [alias])

  const fetchTraineeData = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/etp-mstr/alias/${alias}`,
      )
      if (!response.ok) throw new Error('Failed to fetch trainee data')
      const data = await response.json()

      const traineeDataWithCriticality = await Promise.all(
        data.map(async (trainee) => {
          const criticalityResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/core-comp-criticality/${trainee.etp_mstr_core_comp_code}`,
          )
          const criticalityData = await criticalityResponse.json()
          return {
            ...trainee,
            criticality: criticalityData.criticality,
          }
        }),
      )

      setTraineeData(traineeDataWithCriticality)
      setFilteredData(traineeDataWithCriticality)
    } catch (error) {
      console.error('Error fetching trainee data:', error)
      setTraineeData([])
      setFilteredData([])
    } finally {
      setIsLoading(false)
    }
  }

  const fetchCreditHoursDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/credit-hours-details/${alias}`,
      )
      if (!response.ok) throw new Error('Failed to fetch credit hours details')
      const data = await response.json()
      setCreditHoursDetails(data)
      setShowCreditHoursModal(true)
    } catch (error) {
      console.error('Error fetching credit hours details:', error)
    }
  }

  const fetchCoreCompDetails = async (coreCompCode) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/core-competencies/${coreCompCode}`,
      )
      if (!response.ok)
        throw new Error('Failed to fetch core competency details')
      const data = await response.json()
      setCoreCompDetails(data.core_comp_details)
      setShowModal(true)
    } catch (error) {
      console.error('Error fetching core competency details:', error)
    }
  }

  const handleEditClick = (record) => {
    setSelectedRecord(record)
    setIsEditModalOpen(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCoreCompDetails('') // Clear the details when closing the modal
  }

  const handleSaveDates = async (startDate, completionDate, callback) => {
    if (!selectedRecord) return // Ensure selectedRecord is not undefined

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/etp-mstr/${selectedRecord.etp_mstr_id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            startDate, // Assuming these are in 'YYYY-MM-DD' format
            completionDate,
            coreCompStatus: selectedRecord.etp_mstr_wd_core_comp_status,
            trainerID,
          }),
        },
      )

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const contentType = response.headers.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        await response.json()
      } else {
        console.log('Received non-JSON response:', await response.text())
      }

      if (callback) {
        callback() // Refresh the parent component
      }
    } catch (error) {
      console.error('Error saving dates:', error)
    } finally {
      setIsEditModalOpen(false)
    }
  }

  const fetchFailedWoDetails = async (techDevId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/failed-wo/${techDevId}`,
      )
      if (!response.ok) {
        throw new Error('Failed to fetch failed work order details')
      }
      const data = await response.json()
      setFailedWoDetails(data)
      if (data && data.length > 0) {
        setShowFailedWoModal(true)
      } else {
        console.log('No failed work orders found.')
      }
    } catch (error) {
      console.error('Error fetching failed work order details:', error)
    }
  }

  const handleFailedWoIconClick = async (etpMstrId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/failed-wo-details/${etpMstrId}`,
      )
      if (!response.ok) {
        throw new Error('Failed to fetch failed work order details')
      }
      const data = await response.json()
      setFailedWoDetails(data)
      setShowFailedWoModal(true)
    } catch (error) {
      console.error('Error fetching failed work order details:', error)
    }
  }

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <div className="details-page">
      <h2 className="mb-4 mt-4 text-danger">Trainee Details</h2>
      {/* Dashboard card for total credit hours */}
      <div className="dashboard-cards mb-3">
        <div className="dashboard-card" onClick={fetchCreditHoursDetails}>
          <div className="card-title mx-4">Credit Hours Acquired</div>
          <div className="card-value">{totalCreditHours}</div>
        </div>
      </div>
      <div>
        <select
          className="mb-4"
          value={searchCompCode}
          onChange={(e) => setSearchCompCode(e.target.value)}
        >
          <option value="">Select Core Competency</option>
          {traineeData &&
            Array.from(
              new Set(traineeData.map((item) => item.etp_mstr_core_comp_code)),
            ).map((code) => (
              <option key={code} value={code}>
                {code}
              </option>
            ))}
        </select>
      </div>
      <div className="card-container">
        {filteredData &&
          filteredData.map((record, index) => (
            <div className={`card`} key={index}>
              <div className="card-body">
                <h5 className="card-title">{record.etp_mstr_wd_name}</h5>

                <p className="card-text">
                  <strong>Core Competency:</strong>{' '}
                  {record.etp_mstr_core_comp_code}
                  <i
                    className="ml-4 bi bi-info-circle flash-icon"
                    onClick={() =>
                      handleInfoIconClick(record.etp_mstr_core_comp_code)
                    }
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  ></i>
                </p>
                <p className="card-text">
                  <strong>Core Competency Tile:</strong>{' '}
                  {record.etp_mstr_core_comp}
                </p>
                <p className="card-text">
                  <strong>Type of Course:</strong> {record.criticality}
                </p>
                <p className="card-text">
                  <strong>Req Credit Hours:</strong>{' '}
                  {record.etp_mstr_req_credit_hours}
                </p>
                <p className="card-text">
                  <strong>Min Pass WO:</strong> {record.etp_mstr_min_pass_wo}
                </p>
                <p className="card-text">
                  <strong>WO Passed:</strong> {/* Debugging log */}
                  {console.log(
                    'Debug WO Passed Count for',
                    record.etp_mstr_core_comp_code,
                    ':',
                    woPassedCount[record.etp_mstr_core_comp_code],
                  )}
                  {woPassedCount[record.etp_mstr_core_comp_code] || 0}
                </p>
                <p className="card-text">
                  <strong>WO Failed:</strong> {/* Debugging log */}
                  {console.log(
                    'Debug WO Failed Count for',
                    record.etp_mstr_core_comp_code,
                    ':',
                    woFailedCount[record.etp_mstr_core_comp_code],
                  )}
                  {woFailedCount[record.etp_mstr_core_comp_code] || 0}
                  {woFailedCount[record.etp_mstr_core_comp_code] > 0 && (
                    <button
                      className="icon-button"
                      onClick={() =>
                        handleFailedWoIconClick(record.etp_mstr_id)
                      }
                    >
                      <i className="bi bi-arrow-up-right-square text-danger"></i>
                    </button>
                  )}
                </p>

                <p className="card-text">
                  <strong>Course Content:</strong>{' '}
                  {record.etp_mstr_wd_core_comp_status}
                </p>
                <p className="card-text">
                  <strong>Start Date:</strong>{' '}
                  {formatDate(record.etp_mstr_start_date)}
                </p>
                <p className="card-text">
                  <strong>Completion Date:</strong>{' '}
                  {formatDate(record.etp_mstr_completion_date)}
                </p>
                <p className="card-text">
                  <strong>Site:</strong> {record.etp_mstr_site}
                </p>
                <p className="card-text">
                  <strong>Trainer Approved:</strong>{' '}
                  {record.etp_mstr_trainer_approved}
                </p>
                <p
                  className={`card-text ${getCourseStatusClass(
                    record.etp_mstr_course_status,
                  )}`}
                >
                  <strong>Course Status:</strong>{' '}
                  {record.etp_mstr_course_status}
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() => handleEditClick(record)}
                >
                  Edit Dates
                </button>
              </div>
            </div>
          ))}
      </div>
      {showModal && (
        <CoreCompetencyModal
          details={coreCompDetails}
          onClose={handleCloseModal}
        />
      )}
      {isEditModalOpen && selectedRecord && (
        <EditDatesModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={(start, end) => handleSaveDates(start, end, fetchTraineeData)}
          trainerData={selectedRecord}
          woPassedCount={woPassedCount} // Pass this prop to the modal
        />
      )}
      {showFailedWoModal && (
        <FailedWoDetailsModal
          details={failedWoDetails}
          onClose={() => setShowFailedWoModal(false)}
        />
      )}
      {showCreditHoursModal && (
        <CreditHoursModal
          isOpen={showCreditHoursModal}
          onClose={() => setShowCreditHoursModal(false)}
          details={creditHoursDetails}
        />
      )}
    </div>
  )
}

export default DetailsPage


