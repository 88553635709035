import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../HomePage.css'
import LoadingComponent from './LoadingComponent'

const HomePage = ({ trainerID }) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterSite, setFilterSite] = useState('')
  // New states for training counts
  const [awaitingTrainingCount, setAwaitingTrainingCount] = useState(0)
  const [completedTrainingCount, setCompletedTrainingCount] = useState(0)
  const [trainingStartedCount, setTrainingStartedCount] = useState(0)

  const [groupedData, setGroupedData] = useState({})

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/permissions/${encodeURIComponent(
          trainerID,
        )}/sites`,
      )
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const fetchedData = await response.json()
      setData(fetchedData.sites || [])

      // Process and group data by alias
      const grouped = fetchedData.sites.reduce((acc, item) => {
        const alias = item.etp_mstr_alias
        if (!acc[alias]) {
          acc[alias] = []
        }
        acc[alias].push(item)
        return acc
      }, {})
      setGroupedData(grouped)

      // Update state to display new counts
      if (fetchedData.counts) {
        setAwaitingTrainingCount(fetchedData.counts.awaitingTraining || 0)
        setCompletedTrainingCount(fetchedData.counts.completedTraining || 0)
        setTrainingStartedCount(fetchedData.counts.trainingStarted || 0) // new line
      } else {
        // Log for debugging
        console.error('Counts data not found:', fetchedData)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    if (trainerID) {
      fetchData()
    }
    const loadingTimeout = setTimeout(() => setIsLoading(false), 3000)
    return () => clearTimeout(loadingTimeout)
  }, [trainerID]) // Ensure fetchData is only called when trainerID changes

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleFilterChange = (e) => {
    setFilterSite(e.target.value)
  }

  const filteredData = Array.isArray(data)
    ? data.filter((item) => {
        return (
          (item.etp_mstr_alias
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            item.etp_mstr_wd_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) &&
          (filterSite === '' || item.etp_mstr_site === filterSite)
        )
      })
    : []

  const uniqueSites = [...new Set(data.map((item) => item.etp_mstr_site))]

  if (isLoading) {
    return <LoadingComponent />
  }

  // Filtered data based on search term and site
  const filteredGroupedData = Object.keys(groupedData).filter((alias) => {
    // Check if any data under this alias matches the search term and site filter
    return groupedData[alias].some((item) => {
      const matchesAliasOrName =
        item.etp_mstr_alias.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.etp_mstr_wd_name.toLowerCase().includes(searchTerm.toLowerCase())
      const matchesSite = filterSite === '' || item.etp_mstr_site === filterSite
      return matchesAliasOrName && matchesSite
    })
  })

  return (
    <div className="home-page">
      <div className="content">
        <h1 className="mb-5   text-danger trainer-dashboard-title">
          Trainers Dashboard
        </h1>

        <div className="dashboard-cards mb-3">
          <div className="dashboard-card">
            <div className="card-title">Trainees Awaiting Training</div>
            <div className="card-value">{awaitingTrainingCount}</div>
          </div>
          <div className="dashboard-card">
            <div className="card-title">Trainees Started Training</div>
            <div className="card-value">{trainingStartedCount}</div>
          </div>
          <div className="dashboard-card">
            <div className="card-title">Trainees Completed Training</div>
            <div className="card-value">{completedTrainingCount}</div>
          </div>
        </div>

        <div className="dashboard-header mb-4">
          <div className="row p-3">
            <h4 className="mr-4 text-light ml-3">Your Trainees :</h4>
            <input
              className=" mx-5"
              type="text"
              placeholder="Search Trainees by Name or Alias"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
                width: '300px', // Adjust this value as needed
              }}
            />

            <select
              className=" mr-auto"
              value={filterSite}
              onChange={handleFilterChange}
              style={{ boxShadow: '0 5px 8px rgba(0, 0, 0, 0.5)' }}
            >
              <option value="">All Sites</option>
              {uniqueSites.map((site) => (
                <option key={site} value={site}>
                  {site}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          {filteredGroupedData.map((alias) => {
            // Get the first item from the grouped data for this alias
            const firstItem = groupedData[alias][0]

            return (
              <div key={alias} className="col-md-4 mb-4">
                <div className="card">
                  <div className="card-body text-dark">
                    <h5 className="card-title">Alias: {alias}</h5>
                    {/* Display name and site */}
                    <p className="card-text">
                      Name: {firstItem.etp_mstr_wd_name}
                    </p>
                    <p className="card-text">Site: {firstItem.etp_mstr_site}</p>
                    <Link
                      className="btn-primary p-2"
                      style={{ boxShadow: '0 5px 8px rgba(0, 0, 0, 0.5)' }}
                      to={`/details/${firstItem.etp_mstr_alias}`}
                    >
                      Details
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default HomePage

