// msalConfig.js
import { PublicClientApplication, LogLevel } from '@azure/msal-browser'

const pca = new PublicClientApplication({
  auth: {
    clientId: 'b4bde5d2-9156-4ac8-a778-e6a4ca7e0851',
    authority: 'https://login.microsoftonline.com/46c5178e-a0f4-4f4d-8c40-9598e3d11860',
    // Update the redirectUri to match your Static Web App URL
    redirectUri: 'https://etp-techdev.cushwake.com',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
 system: {
  loggerOptions: {
    loggerCallback(logLevel, message, containsPii) {
      console.log(message);
    },
    logLevel: LogLevel.Verbose, // Corrected from msal.LogLevel.Verbose
    piiLoggingEnabled: false
  }
}
});

export default pca;
