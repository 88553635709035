import React, { useState } from 'react'
import './FailedWoDetailsModal.css'

const FailedWoDetailsModal = ({ details, onClose }) => {
  const [showResponses, setShowResponses] = useState({})
  const [localDetails, setLocalDetails] = useState(details) // Use a local state to manage details

  const toggleResponses = (index) => {
    setShowResponses((prev) => ({ ...prev, [index]: !prev[index] }))
  }

  if (!localDetails || localDetails.length === 0) {
    return <p>No failed work order details available.</p>
  }

  const handleAppeal = async (woNum, index) => {
    const detail = localDetails[index]

    console.log('Regrade completion date: ', detail.regrade_comp_date) // Debugging

    // Check if regrade_comp_date is present, indicating the WO has already been reviewed
    if (detail.regrade_comp_date) {
      console.log('Already reviewed warning triggered') // Debugging
      alert(
        `This Work Order has already been reviewed on ${formatDate(
          detail.regrade_comp_date,
        )}.`,
      )
      return
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/failed-wo/appeal-grade/${woNum}`,
        { method: 'PUT' },
      )
      if (!response.ok) throw new Error('Failed to appeal grade')
      const updatedDetails = [...localDetails]
      updatedDetails[index].regrade_flag = 'Yes' // Set regrade_flag to 'Yes'
      setLocalDetails(updatedDetails) // Update local details
      alert('Grade appeal successful!')
    } catch (error) {
      console.error('Error appealing grade:', error)
      alert('Error processing grade appeal')
    }
  }

  const handleWithdrawAppeal = async (woNum, index) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/failed-wo/withdraw-appeal/${woNum}`,
        { method: 'PUT' },
      )
      if (!response.ok) throw new Error('Failed to withdraw appeal')
      const updatedDetails = [...localDetails]
      updatedDetails[index].regrade_flag = null // Clear regrade_flag
      setLocalDetails(updatedDetails) // Update local details
      alert('Appeal withdrawn successfully!')
    } catch (error) {
      console.error('Error withdrawing appeal:', error)
      alert('Error processing appeal withdrawal')
    }
  }

  const formatDate = (dateString) => {
    return dateString ? new Date(dateString).toLocaleDateString() : ''
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Failed Work Order Details</h2>
        <div className="modal-body">
          {details.map((detail, index) => (
            <div key={index} className="detail-container">
              <p>
                <strong>WO Number:</strong> {detail.wo_num}
              </p>
              <p>
                <strong>Completion Date:</strong>{' '}
                {detail.wo_comp_date.split('T')[0]}
              </p>

              <p>
                <strong>Template:</strong> {detail.template || 'N/A'}
              </p>
              <p>
                <strong>Total Score:</strong> {detail.total_score}
              </p>

              {detail.regrade_flag === 'Yes' && (
                <p className="appeal-pending">Appeal Pending</p>
              )}

              <div className="button-container">
                <button className="mr-4" onClick={() => toggleResponses(index)}>
                  {showResponses[index] ? 'Hide Responses' : 'Show Responses'}
                </button>

                {detail.regrade_flag === 'Yes' ? (
                  <button
                    className="withdraw btn-warning"
                    onClick={() => handleWithdrawAppeal(detail.wo_num, index)}
                  >
                    Withdraw Appeal
                  </button>
                ) : (
                  <button
                    className="btn-appeal"
                    onClick={() => handleAppeal(detail.wo_num, index)}
                  >
                    Appeal Grade
                  </button>
                )}
              </div>

              {showResponses[index] && (
                <div className="mt-3">
                  <p>
                    <strong>Response 1:</strong> {detail.response_1}
                  </p>
                  <p>
                    <strong>Response 2:</strong> {detail.response_2}
                  </p>
                  <p>
                    <strong>Response 3:</strong> {detail.response_3}
                  </p>
                  <p>
                    <strong>Response 4:</strong> {detail.response_4}
                  </p>
                  <p>
                    <strong>Response 5:</strong> {detail.response_5}
                  </p>
                  <p>
                    <strong>Score 1:</strong> {detail.score_1}
                  </p>
                  <p>
                    <strong>Score 2:</strong> {detail.score_2}
                  </p>
                  <p>
                    <strong>Score 3:</strong> {detail.score_3}
                  </p>
                  <p>
                    <strong>Score 4:</strong> {detail.score_4}
                  </p>
                  <p>
                    <strong>Score 5:</strong> {detail.score_5}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default FailedWoDetailsModal
