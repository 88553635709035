import React, { useState, useEffect } from 'react'
import './GraderDashboard.css'
import LoadingComponent from './LoadingComponent'
import RegradeModal from './RegradeModal'

const GraderAdminDashboard = ({ trainerID }) => {
  const [regradeRequests, setRegradeRequests] = useState([])
  const [pendingAppealsCount, setPendingAppealsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)

  const refreshData = async () => {
    // Re-fetch the data
    try {
      const regradeResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/regrade-requests`,
      )
      const appealsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/pending-appeals-count`,
      )

      if (!regradeResponse.ok || !appealsResponse.ok) throw new Error()

      const regradeData = await regradeResponse.json()
      const appealsCount = await appealsResponse.json()

      setRegradeRequests(regradeData)
      setPendingAppealsCount(appealsCount)
    } catch (error) {
      console.error('Error refreshing data:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regradeResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/regrade-requests`,
        )
        const appealsResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/pending-appeals-count`,
        )

        if (!regradeResponse.ok || !appealsResponse.ok) throw new Error()

        const regradeData = await regradeResponse.json()
        const appealsCount = await appealsResponse.json()

        setRegradeRequests(regradeData)
        setPendingAppealsCount(appealsCount)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const handleOpenModal = (request) => {
    setSelectedRequest(request)
    setIsModalOpen(true)
  }

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <div className="grader-admin-dashboard">
      <h1>Grader Dashboard</h1>
      <p>Welcome to the Grader Dashboard, {trainerID}</p>
      <div className="dashboard-cards mb-3">
        <div className="dashboard-card">
          <div className="card-title">Pending Appeals</div>
          <div className="card-value">{pendingAppealsCount}</div>
        </div>
        {/* Other dashboard cards... */}
      </div>
      <table>
        <thead>
          <tr>
            <th>Alias</th>
            <th>WO Number</th>
            <th>Date of Appeal</th>
            <th>Template</th>
            <th>Total Score</th>
            <th>Action</th>
            {/* ... other headers ... */}
          </tr>
        </thead>
        <tbody>
          {regradeRequests.map((request, index) => (
            <tr key={index}>
              <td>{request.pp_alias}</td>
              <td>{request.wo_num}</td>
              <td>
                {request.regrade_gen_date
                  ? request.regrade_gen_date.split('T')[0]
                  : 'N/A'}
              </td>
              <td>{request.template}</td>
              <td>{request.total_score}</td>
              <td>
                <button onClick={() => handleOpenModal(request)}>
                  View Details
                </button>
              </td>
              {/* ... other table data ... */}
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <RegradeModal
          request={selectedRequest}
          onClose={() => setIsModalOpen(false)}
          refreshData={refreshData} // Add this line
          trainerID={trainerID}
        />
      )}
    </div>
  )
}

export default GraderAdminDashboard
