// PermissionDetailsModal.js
import React from 'react'

const PermissionDetailsModal = ({ isOpen, onClose, trainerPermissions }) => {
  if (!isOpen) return null

  return (
    <div className="my-custom-modal">
      <div className="my-custom-modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h3>Site Permissions</h3>
        <ul>
          {trainerPermissions.map((perm, index) => (
            <li key={index}>
              {perm.SiteID}: {perm.SiteName} - Level: {perm.PermissionLevel}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PermissionDetailsModal
