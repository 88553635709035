// MSALContext.js
import React, { createContext, useEffect, useState } from 'react'
import pca from './Auth/msalConfig'

export const MSALContext = createContext(null)

export const MSALProvider = ({ children }) => {
  const [msalInstance, setMsalInstance] = useState(null)

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await pca.initialize()
        setMsalInstance(pca)
      } catch (error) {
        console.error('MSAL initialization error:', error)
      }
    }

    initializeMsal()
  }, [])

  return (
    <MSALContext.Provider value={{ msalInstance }}>
      {children}
    </MSALContext.Provider>
  )
}