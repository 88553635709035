import React, { useState, useEffect } from 'react'

const formatDate = (dateStr) => {
  return dateStr ? dateStr.split('T')[0] : '' // Splits the date and time, and returns only the date part
}

const EditDatesModal = ({
  isOpen,
  onClose,
  onSave,
  trainerData,
  woPassedCount,
}) => {
  const [startDate, setStartDate] = useState('')
  const [completionDate, setCompletionDate] = useState('')

  useEffect(() => {
    if (trainerData) {
      setStartDate(formatDate(trainerData.etp_mstr_start_date))
      setCompletionDate(formatDate(trainerData.etp_mstr_completion_date))
    }
  }, [trainerData])

  if (!isOpen) return null

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!completionDate) {
      onSave(startDate, null)
      return
    }

    // Ensure Workday Status is 'Completed'
    if (trainerData.etp_mstr_wd_core_comp_status !== 'Completed') {
      alert(
        'Completion date can only be set if the Workday status is Completed.',
      )
      return
    }

    // Ensure WO Passed is greater than or equal to Min Pass WO
    const passedWoCount =
      woPassedCount[trainerData.etp_mstr_core_comp_code] || 0
    if (passedWoCount < trainerData.etp_mstr_min_pass_wo) {
      alert(
        'Completion date can only be set if the WO Passed count meets or exceeds the Min Pass WO count.',
      )
      return
    }

    onSave(startDate, completionDate)
  }

  return (
    <div className="modal show" style={{ display: 'block' }}>
      {' '}
      {/* Bootstrap modal classes */}
      <div className="modal-dialog" style={{ marginTop: '150px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-dark">Edit Dates</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group text-dark">
                <label htmlFor="startDate">Start Date:</label>
                <input
                  type="date"
                  className="form-control" // Bootstrap class for styling
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="form-group text-dark">
                <label htmlFor="completionDate">Completion Date:</label>
                <input
                  type="date"
                  className="form-control" // Bootstrap class for styling
                  id="completionDate"
                  value={completionDate}
                  onChange={(e) => setCompletionDate(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditDatesModal


