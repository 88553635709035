import { useEffect } from 'react'

const useInactivityLogout = (msalInstance, onLogout, timeout = 600000) => {
  // 10 minutes
  useEffect(() => {
    let logoutTimer

    const resetTimer = () => {
      clearTimeout(logoutTimer)
      logoutTimer = setTimeout(onLogout, timeout)
    }

    document.addEventListener('mousemove', resetTimer)
    document.addEventListener('keypress', resetTimer)

    resetTimer()

    return () => {
      clearTimeout(logoutTimer)
      document.removeEventListener('mousemove', resetTimer)
      document.removeEventListener('keypress', resetTimer)
    }
  }, [msalInstance, onLogout, timeout])
}

export default useInactivityLogout