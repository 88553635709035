import React, { useState, useEffect } from 'react'
import './RegradeModal.css'

const RegradeModal = ({ request, onClose, trainerID, refreshData }) => {
  const [newScore, setNewScore] = useState(0)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const fetchQuestions = async () => {
      if (request && request.template) {
        try {
          const encodedTemplateId = encodeURIComponent(request.template)
          // Update the URL to match the new route
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/templates/template-questions/${encodedTemplateId}`,
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const data = await response.json()
          if (data.length > 0) {
            setQuestions(data[0]) // Set the first object from the array
          } else {
            console.error('No data received')
          }
        } catch (error) {
          console.error('Error fetching questions:', error)
        }
      }
    }

    fetchQuestions()
  }, [request])

  if (!request) return null

  const handleScoreChange = (e) => {
    setNewScore(e.target.value)
  }

  const submitRegrade = async () => {
    const confirmChange = window.confirm(
      'Are you sure you want to change this score?',
    )
    if (confirmChange) {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/regrade-score`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ woNum: request.wo_num, newScore, trainerID }),
        })
        alert('Score updated successfully')
        onClose() // Close the modal
        refreshData() // Refresh the dashboard data
      } catch (error) {
        console.error('Error updating score:', error)
        alert('Error updating score')
      }
    }
  }

  // Display Questions
  const renderQuestions = () => {
    return Object.entries(questions).map(([key, value], index) => (
      <p key={index}>
        <strong>{key.replace('_', ' ')}:</strong> {value}
      </p>
    ))
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Regrade Request Details</h2>
        <p>
          <strong>Alias:</strong> {request?.pp_alias}
        </p>
        <p>
          <strong>Appeal Date:</strong>{' '}
          {request?.regrade_gen_date
            ? request.regrade_gen_date.split('T')[0]
            : 'N/A'}
        </p>
        <p>
          <strong>Template:</strong> {request?.template}
        </p>
        <p>
          <strong>Score:</strong> {request?.total_score}
        </p>

        <select value={newScore} onChange={handleScoreChange}>
          {[...Array(11)].map((_, i) => (
            <option key={i} value={i * 5}>
              {i * 5}
            </option>
          ))}
        </select>
        <button onClick={submitRegrade}>Submit</button>

        {/* Render Questions */}
        {renderQuestions()}

        {/* Responses */}
        <p>
          <strong>Response 1:</strong> {request?.response_1}
        </p>
        <p>
          <strong>Response 2:</strong> {request?.response_2}
        </p>
        <p>
          <strong>Response 3:</strong> {request?.response_3}
        </p>
        <p>
          <strong>Response 4:</strong> {request?.response_4}
        </p>
        <p>
          <strong>Response 5:</strong> {request?.response_5}
        </p>

        <button onClick={onClose}>Close</button>
      </div>
    </div>
  )
}

export default RegradeModal
