// index.js
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import { MSALProvider } from './MSALContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MSALProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </MSALProvider>,
);

